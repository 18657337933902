/**
 * Main Head Script File
 *
 * This is loaded in the header of all pages. Great care should be used when adding
 * scripts to this file. Only scripts that must be loaded before everything else should
 * be added here. This file will pause loading until it executes.
 */
window.jQuery = window.$ = require('jquery');

/**
 * Execute the given function when the window load event fires, or imediately if it has already fired.
 * @param {function} cb The function to execute after the window.load event
 */
window.afterLoad = (cb) => {
    if (document.readyState === 'complete') {
        // the load event already fired. just call the callback now.
        cb();
    } else {
        window.addEventListener('load', cb);
    }
};

/**
 * Get closest parent, given the current selector
 * @param {*} el - element to iterate over
 * @param {string} selector - selector to search for
 * @returns {*} - element of the provided selector or null
 */
function getClosest(el, selector) {
    var iteratedElement = el;
    while (iteratedElement) {
        if (iteratedElement.classList) {
            for (var i = 0; i < iteratedElement.classList.length; i++) {
                if (iteratedElement.classList[i] === selector) {
                    return iteratedElement;
                }
            }
        }
        iteratedElement = iteratedElement.parentNode;
    }
    return null;
}

window.imageError = function (img, type) {
    var defaultImgLink = typeof resources !== 'undefined' && resources.images && resources.images.placeholderImgLarge ? resources.images.placeholderImgLarge : ''; // eslint-disable-line no-undef
    var defaultAltText = typeof resources !== 'undefined' && resources.images && resources.images.placeholderImgAlt ? resources.images.placeholderImgAlt : ''; // eslint-disable-line no-undef
    var appendType = type || '';
    if (defaultImgLink) {
        var parentElement;
        // If data-src or data-src-alt are on the img tag, this means that it is a quick view image, which means parentElement will be set
        if (img.attributes['data-src'] && img.attributes['data-src'].value === img.src) {
            img.setAttribute('data-src', defaultImgLink);
            img.setAttribute('data-srcset', defaultImgLink);
            img.setAttribute('srcset', defaultImgLink);
            parentElement = getClosest(img, 'product-tile');
            if (parentElement) {
                parentElement.querySelector('a.js-color-swatch.selected').setAttribute('data-swatch-img', defaultImgLink);
            }
        } else if (img.attributes['data-src-alt'] && img.attributes['data-src-alt'].value === img.src) {
            img.setAttribute('data-src-alt', defaultImgLink);
            parentElement = getClosest(img, 'product-tile');
            if (parentElement) {
                parentElement.querySelector('a.js-color-swatch.selected').setAttribute('data-swatch-img-alt', defaultImgLink);
            }
        }
        if (appendType === 'retina') {
            appendType = typeof resources !== 'undefined' && resources.pdpProductImgAppend ? resources.pdpProductImgAppend : ''; // eslint-disable-line no-undef
            img.previousElementSibling.setAttribute('srcset', defaultImgLink + appendType); // eslint-disable-line no-param-reassign
        } else if (appendType === 'large') {
            appendType = typeof resources !== 'undefined' && resources.pdpProductImgAppend ? resources.pdpProductImgAppend : ''; // eslint-disable-line no-undef
        } else if (appendType === 'thumb') {
            appendType = typeof resources !== 'undefined' && resources.pdpThumbImgAppend ? resources.pdpThumbImgAppend : ''; // eslint-disable-line no-undef
        } else {
            appendType = typeof resources !== 'undefined' && resources.gridImageAppend ? resources.gridImageAppend : ''; // eslint-disable-line no-undef
        }
        img.src = defaultImgLink + appendType; // eslint-disable-line no-param-reassign
        img.srcset = defaultImgLink + appendType; // eslint-disable-line no-param-reassign
        img.alt = defaultAltText; // eslint-disable-line no-param-reassign
    }
};

require('./scene7/s7ImageSet');
var pageVisitReferrer = require('./dataLayer/pageVisitReferrer');
var sessionSpecificLocalStorageHelper = require('./helper/sessionSpecificLocalStorageHelper');

(function () {
    var currentState = window.history.state;
    var dataLayer = window.digitalData;

    if (dataLayer) {
        var blankReferrer = false;

        /**
         * Handles client code that alters page URL or history.
         * This happens, for example, when a user quick shops on the product grid page.
         * In those cases, rather than capturing ever altered URL as a usage of a referrer,
         * state data is amended to note the referrer should be cleared on page load when using browser back/forward buttons.
         * Reads data captured by historyHooks.js
         */
        if (currentState &&
            currentState.digitalData &&
            currentState.digitalData.blankVisitReferrerOnLoad) {
            blankReferrer = true;
        } else {
            var captureResult = pageVisitReferrer.captureSingleUseReferrer();

            if (captureResult.previouslyCaptured) {
                blankReferrer = true;
            }
        }

        dataLayer.pageVisit = {
            referrer: blankReferrer ? window.location.origin : document.referrer
        };

        var userSpecificDataPopulatedFromClientCache = false;

        // If server provides user-specific data then use it, otherwise pull from cache
        if (!dataLayer.user) {
            var cacheEntry = sessionSpecificLocalStorageHelper.getItem('dataLayerPersistence.userSpecificData');

            if (cacheEntry && cacheEntry.data) {
                Object.assign(dataLayer, cacheEntry.data);
                userSpecificDataPopulatedFromClientCache = true;
            } else {
                // eslint-disable-next-line no-console
                console.error('Data layer provided by the server is missing user data and none previously cached on the client');
            }
        }

        // eslint-disable-next-line no-undef
        clientData.user = {
            isLoggedIn: dataLayer.user && dataLayer.user.loggedIn
        };

        dataLayer.userSpecificDataPopulatedFromClientCache = userSpecificDataPopulatedFromClientCache;
    }
}());
